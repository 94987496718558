import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [firstDropdown, setFirstDropdown] = useState('');
  const [secondDropdown, setSecondDropdown] = useState('');
  const [departments, setDepartments] = useState([]);
  const [editableFields, setEditableFields] = useState({});
  const [showUpdatePropertyPage, setShowUpdatePropertyPage] = useState(false); 
  const [showInsertAnnualValuesPage, setShowInsertAnnualValuesPage] = useState(false); 
  const [showDeleteMonthPage, setShowDeleteMonthPage] = useState(false); 
  const [ano, setAno] = useState('');
  const [indicador, setIndicador] = useState('');
  const [valorIndicador, setValorIndicador] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1899 + 1 }, (_, i) => 1900 + i).reverse();
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [showPassword, setShowPassword] = useState(false);
  const [userType, setUserType] = useState(''); 
  const isAdmin = userType === 'admin';
  const [currentValues, setCurrentValues] = useState(null);
  const [propietario, setPropietario] = useState('');
  const [idDepto, setIdDepto] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [showCreateOrUpdateDeptPage, setShowCreateOrUpdateDeptPage] = useState(false);
  const [showManageAccessPage, setShowManageAccessPage] = useState(false);
  const [formattedAnnualValues, setFormattedAnnualValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showUploadDataPage, setShowUploadDataPage] = useState(false); 

  useEffect(() => {
    axios.get('http://admin.managementdoral.com/api/get-deptos-info', {
      params: { userType }
    })
    .then(response => {
      const sortedDepartments = response.data.departments.map(department => ({
        ...department,
        deptos: department.deptos.sort((a, b) => a.ID_Depto.localeCompare(b.ID_Depto))
      })).sort((a, b) => a.label.localeCompare(b.label));
      setDepartments(sortedDepartments);
    })
    .catch(error => console.error('Error fetching data:', error));
  }, [userType]);

  const handleFirstDropdownChange = (e) => {
    setFirstDropdown(e.target.value);
    setSecondDropdown('');
    setEditableFields({});
  };

  const handleSecondDropdownChange = (e) => {
    setSecondDropdown(e.target.value);
    const deptoInfo = departments.find(d => d.value === firstDropdown)?.deptos.find(depto => depto.ID_Depto === e.target.value);
  
    if (deptoInfo) {
      ['FECHA_DE_COMPRA', 'fecha_inicio_contrato', 'fecha_termino_contrato'].forEach(field => {
        if (deptoInfo[field] && typeof deptoInfo[field] === 'object') {
          deptoInfo[field] = deptoInfo[field].value;
        }
      });
      // INICIO CAMBIOS: Asegurar que 'activo' sea booleano
      deptoInfo.activo = Boolean(deptoInfo.activo);
      // FIN CAMBIOS
    }
  
    setEditableFields(deptoInfo || {});
  
    if (firstDropdown && e.target.value) {
      axios.get('http://admin.managementdoral.com/api/get-valores-anuales-formatted', {
        params: { Propietario: firstDropdown, ID_Depto: e.target.value }
      })
      .then(response => setFormattedAnnualValues(response.data))
      .catch(error => console.error('Error fetching formatted annual values:', error));
    }
  };

  const handleInputChange = (e, columnName) => {
    setEditableFields({
      ...editableFields,
      [columnName]: e.target.value
    });
  };

  const handleCheckboxChange = (e) => {
    setEditableFields({
      ...editableFields,
      activo: e.target.checked
    });
  };

  const handleUpdate = () => {
    const { Propietario, ID_Depto, ...updatedFields } = editableFields;
  
    // Ensure 'activo' is always included
    if (editableFields.activo === undefined) {
      updatedFields.activo = false;
    }
  
    const nonBlankFields = Object.fromEntries(
      Object.entries(updatedFields).filter(
        ([_, value]) => value !== null && value !== undefined && (typeof value !== 'string' || value.trim() !== '')
      )
    );
  
    axios
      .put(`http://admin.managementdoral.com/api/update-depto`, { Propietario, ID_Depto, updatedFields: nonBlankFields })
      .then(response => alert('Data updated successfully!'))
      .catch(error => console.error('Error updating data:', error));
  };
  

  const handleLogin = () => {
    axios.post(`http://admin.managementdoral.com/api/login`, { user: username, password })
    .then(response => {
      if (response.data.success) {
        setLoggedIn(true);
        setUserType(response.data.userType); 
      } else {
        alert(response.data.message);
      }
    })
    .catch(error => {
      console.error('Error during login:', error);
      alert('An error occurred during login.');
    }); 
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUsername('');
    setPassword('');
    setUserType('');
  };

  const handleDeleteMonth = () => {
    if (!selectedYear || !selectedMonth) {
        alert('Please select both month and year before proceeding.');
        return;
    }

    axios.post(`http://admin.managementdoral.com/api/delete-month`, {
        year: selectedYear,
        month: selectedMonth
    })
    .then(response => {
        if (response.data.success) {
            alert(`Data for ${selectedMonth} ${selectedYear} deleted successfully!`);
        } else {
            alert('Failed to delete data. ' + response.data.message);
        }
    })
    .catch(error => {
        console.error('Error deleting month data:', error);
        alert('An error occurred while deleting month data.');
    });
};

const handleFileChange = (e) => {
  const file = e.target.files[0];
  setUploadedFile(file);
};

const handleFileUpload = (event) => {
  event.preventDefault();

  if (!uploadedFile) {
      alert("Please select a file before uploading.");
      return;
  }

  const formData = new FormData();
  formData.append('excelFile', uploadedFile);

  axios.post(`http://admin.managementdoral.com/upload-and-clean`, formData)
  .then(response => {
      alert('Data uploaded and processed successfully!');
  })
  .catch(error => {
      console.error('Error uploading data:', error);
      if (error.response && error.response.data) {
          alert(error.response.data); 
      } else {
          alert('An error occurred while uploading data.');
      }
  });
};

const fetchAnualValues = useCallback(() => {
  if (firstDropdown && secondDropdown && ano && indicador) {
    axios.get(`http://admin.managementdoral.com/api/get-anual-values`, {
      params: { Propietario: firstDropdown, ID_Depto: secondDropdown, ano, indicador }
    })
    .then(response => {
      setCurrentValues(response.data[0] || null);
      setValorIndicador(response.data[0] ? response.data[0].valor_indicador : '');
    })
    .catch(error => console.error('Error fetching annual values:', error));
  }
}, [firstDropdown, secondDropdown, ano, indicador]); 

useEffect(() => {
  fetchAnualValues();
}, [fetchAnualValues]);

const handleInsertOrUpdate = () => {
  setLoading(true);
  const url = currentValues ? 'http://admin.managementdoral.com/api/update-valores-anuales' : 'http://admin.managementdoral.com/api/insert-valores-anuales';
  const method = currentValues ? 'put' : 'post';
  axios[method](url, {
    Propietario: firstDropdown,
    ID_Depto: secondDropdown,
    ano,
    indicador,
    valor_indicador: parseFloat(valorIndicador),
  })
  .then(response => {
    alert('Operation successful!');
    setCurrentValues({
      Propietario: firstDropdown,
      ID_Depto: secondDropdown,
      ano,
      indicador,
      valor_indicador: parseFloat(valorIndicador),
    });
    fetchAnualValues();
  })
  .catch(error => {
    console.error('Error submitting data:', error);
    alert('Error during operation');
  })
  .finally(() => {
    setLoading(false); 
  });
};

const handlePropietarioChange = (event) => {
  setPropietario(event.target.value);
};

const handleIdDeptoChange = (event) => {
  setIdDepto(event.target.value);
};

const handleUpdateOrCreate = () => {
  const url = 'http://admin.managementdoral.com/api/create-or-update-depto';
  const payload = {
    Propietario: propietario,
    ID_Depto: idDepto,
  };

  axios.post(url, payload)
    .then(response => {
      alert('Operation successful!');
      setPropietario('');
      setIdDepto(''); 
      fetchDepartmentsInfo(); 
      setShowCreateOrUpdateDeptPage(false);
      setShowUpdatePropertyPage(true);
    })
    .catch(error => {
      console.error('Error during the operation:', error);
      alert('Error: ' + (error.response?.data || 'Operation failed'));
    });
};

const fetchDepartmentsInfo = useCallback(() => {
  axios.get('http://admin.managementdoral.com/api/get-deptos-info', {
    params: { userType }
  })
  .then(response => {
    const sortedDepartments = response.data.departments.map(department => ({
      ...department,
      deptos: department.deptos.sort((a, b) => a.ID_Depto.localeCompare(b.ID_Depto))
    })).sort((a, b) => a.label.localeCompare(b.label));
    setDepartments(sortedDepartments);
  })
  .catch(error => console.error('Error fetching data:', error));
}, [userType]);

useEffect(() => {
  fetchDepartmentsInfo();
}, [fetchDepartmentsInfo]);

const [emailOptions, setEmailOptions] = useState([]);
const [selectedEmail, setSelectedEmail] = useState(null);
const [isAddingNewEmail, setIsAddingNewEmail] = useState(false);
const [newEmail, setNewEmail] = useState('');
const [selectedOwners, setSelectedOwners] = useState([]);

useEffect(() => {
  axios.get('http://admin.managementdoral.com/api/get-emails')
    .then(response => {
      const emailData = response.data
        .map(email => ({
          label: email.toLowerCase(),
          value: email
        }))
        .sort((a, b) => a.label.localeCompare(b.label)); 
  
      setEmailOptions(emailData);
    })
    .catch(error => {
      console.error('Error fetching emails:', error);
    });
}, []);

const fetchEmails = () => {
  axios.get('http://admin.managementdoral.com/api/get-emails')
    .then(response => {
      const emailData = response.data
        .map(email => ({
          label: email.toLowerCase(),
          value: email
        }))
        .sort((a, b) => a.label.localeCompare(b.label)); 
  
      setEmailOptions(emailData); 
    })
    .catch(error => {
      console.error('Error fetching emails:', error);
      alert('Failed to fetch emails. Please try again later.');
    });
};

useEffect(() => {
  fetchEmails();  
}, []); 

const toggleEmailInput = () => {
  setIsAddingNewEmail(!isAddingNewEmail);
  if (isAddingNewEmail) {
    setNewEmail('');
  } else {
    setSelectedEmail(null);
  }
};

const handleEmailChange = async (event) => {
  const email = event.target.value;
  setSelectedEmail(emailOptions.find(option => option.value === email));
  
  try {
    const response = await axios.get(`http://admin.managementdoral.com/api/get-access-details`, { params: { email } });
    setSelectedOwners(response.data);
  } catch (error) {
    console.error('Error fetching access details:', error);
    alert('Failed to fetch access details.');
  }
};

const handleNewEmailChange = (e) => {
  setNewEmail(e.target.value);
};

const handleOwnerSelection = (propietario) => {
  setSelectedOwners(prev => {
    if (prev.includes(propietario)) {
      return prev.filter(p => p !== propietario);
    } else {
      return [...prev, propietario];
    }
  });
};

const submitAccessChange = (action) => {
  const emailToSubmit = isAddingNewEmail ? newEmail : (selectedEmail ? selectedEmail.value : '');
  
  if (!emailToSubmit || selectedOwners.length === 0) {
    alert('Please select or enter an email and at least one propietario.');
    return;
  }

  axios.post(`http://admin.managementdoral.com/api/${action}-access`, {
    Emails: emailToSubmit,
    Propietarios: selectedOwners
  })
  .then(response => {
    if (response.data.success) {
      alert(response.data.message); 
      if (isAddingNewEmail) {
        fetchEmails(); 
        setIsAddingNewEmail(false); 
        setNewEmail(''); 
      }
    } else {
      if (response.data.errors && response.data.errors.length > 0) {
        alert(`Failed to update access due to conflicts: ${response.data.errors.join(", ")}`);
      } else {
        alert(response.data.message); 
      }
    }
  })
  .catch(error => {
    console.error('Error updating access:', error);
    const errorMessage = error.response && error.response.data && error.response.data.message
                          ? error.response.data.message
                          : 'Failed to update access due to server error.';
    alert(errorMessage);
  });
};

if (!loggedIn) {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
          <div className="col-md-4">
            <h2>Login</h2>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">Username</label>
              <input 
                type="text" 
                className="form-control" 
                id="username" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                required />
            </div>
            <div className="mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <div className="input-group">
              <input 
                type={showPassword ? 'text' : 'password'} 
                className="form-control" 
                id="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
              />
              <div className="input-group-append">
                <button 
                  className="btn btn-outline-secondary custom-btn" 
                  type="button" 
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </div>
          </div>
          <button type="button" className="btn btn-primary custom-btn" onClick={handleLogin}>Login</button>
        </div>
      </div>
    </div>
  );
}

return (
  <div className="container">
    <div className="d-flex justify-content-end">
      <span className="mr-3 align-self-center">Logged in as: {username}</span>
      <button className="btn btn-warning custom-btn" onClick={handleLogout}>Sign Out</button>
    </div>
    {showCreateOrUpdateDeptPage ? (
    <div>
      <h1 className="text-center mt-4">{isUpdating ? 'Update' : 'Create'} Department</h1>
      <button className="btn btn-secondary mb-4 custom-btn" onClick={() => setShowCreateOrUpdateDeptPage(false)}>Back to Menu</button>
      <div className="form-group">
        <label>Propietario:</label>
        <input
          type="text"
          className="form-control"
          value={propietario}
          onChange={handlePropietarioChange}
          placeholder="Propietario"
        />
      </div>
      <div className="form-group">
        <label>ID Depto:</label>
        <input
          type="text"
          className="form-control"
          value={idDepto}
          onChange={handleIdDeptoChange}
          placeholder="ID Depto"
        />
      </div>
      <button className="btn btn-success custom-btn" onClick={handleUpdateOrCreate}>
        {isUpdating ? 'Update' : 'Create'}
      </button>
      <button className="btn btn-primary custom-btn" onClick={() => {
        setShowCreateOrUpdateDeptPage(false);
        setShowUpdatePropertyPage(true);  
      }}>Switch to Update</button>
    </div>
  ) : showUpdatePropertyPage ? (
    <div>
      <h1 className="text-center mt-4">Update Property Information</h1>
      <button className="btn btn-secondary mb-4 custom-btn" onClick={() => setShowUpdatePropertyPage(false)}>Back to Menu</button>
      <form>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="form-group">
              <label>Propietario:</label>
              <select className="form-control" value={firstDropdown} onChange={handleFirstDropdownChange}>
                <option value="" disabled>Select a Propietario</option>
                {departments && departments.map(department => (
                  <option key={department.value} value={department.value}>{department.label}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>ID_Depto:</label>
              <select className="form-control" value={secondDropdown} disabled={!firstDropdown} onChange={handleSecondDropdownChange}>
                <option value="" disabled>Select an ID_Depto</option>
                {firstDropdown && departments.find(d => d.value === firstDropdown)?.deptos.map(depto => (
                  <option key={depto.ID_Depto} value={depto.ID_Depto}>{depto.ID_Depto}</option>
                ))}
              </select>
            </div>
            {editableFields && Object.keys(editableFields).filter(key => key !== 'ID_Depto' && key !== 'Propietario' && key !== 'activo').map(columnName => (
              <div className="form-group" key={columnName}>
                <label>{columnName}:</label>
                <input
                  type={columnName === 'FECHA_DE_COMPRA' || columnName === 'fecha_inicio_contrato' || columnName === 'fecha_termino_contrato' ? 'date' : 'text'}
                  className="form-control"
                  value={editableFields[columnName] || ''}
                  onChange={(e) => handleInputChange(e, columnName)}
                />
              </div>
            ))}
{firstDropdown && secondDropdown && editableFields && (
    <div className="form-group">
        <div className="form-check">
            <input
                className="form-check-input"
                type="checkbox"
                checked={editableFields.activo || false}
                onChange={handleCheckboxChange}
            />
            <label className="form-check-label">
                Activo
            </label>
        </div>
    </div>
)}

            <button type="button" className="btn btn-success custom-btn" onClick={handleUpdate}>Update</button>
            <button className="btn btn-primary custom-btn" onClick={() => {
              setShowCreateOrUpdateDeptPage(true);
              setShowUpdatePropertyPage(false);  
            }}>Create Department</button>
          </div>
        </div>
      </form>
    </div>
  ) : showInsertAnnualValuesPage ? (
    <div>
      <h1 className="text-center mt-4">Insert Annual Values</h1>
      <button className="btn btn-secondary mb-4 custom-btn" onClick={() => setShowInsertAnnualValuesPage(false)}>Back to Menu</button>
      <form>
        <div className="form-group">
          <label>Propietario:</label>
          <select className="form-control" value={firstDropdown} onChange={handleFirstDropdownChange}>
            <option value="" disabled>Select a Propietario</option>
            {departments.map(department => (
              <option key={department.value} value={department.value}>{department.label}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>ID_Depto:</label>
          <select className="form-control" value={secondDropdown} disabled={!firstDropdown} onChange={handleSecondDropdownChange}>
            <option value="" disabled>Select an ID_Depto</option>
            {firstDropdown && departments.find(d => d.value === firstDropdown)?.deptos.map(depto => (
              <option key={depto.ID_Depto} value={depto.ID_Depto}>{depto.ID_Depto}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Año:</label>
          <select className="form-control" value={ano} onChange={(e) => setAno(e.target.value)}>
            <option value="" disabled>Select a Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Indicador:</label>
          <select className="form-control" value={indicador} onChange={(e) => setIndicador(e.target.value)}>
            <option value="" disabled>Select an Indicador</option>
            <option value="Avaluo">Avaluo</option>
            <option value="Administracion 2">Administracion 2</option>
            <option value="Tax">Tax</option>
            <option value="Valor de Mercado">Valor de Mercado</option>
          </select>
        </div>

        <div className="form-group">
          <label>Valor Indicador:</label>
          <input type="number" className="form-control" value={valorIndicador} onChange={(e) => setValorIndicador(e.target.value)} />
        </div>
        <button type="button" className="btn btn-success custom-btn" onClick={handleInsertOrUpdate} disabled={loading}>
            {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : null}
            {currentValues ? 'Update' : 'Insert'}
          </button>
      </form>
      
      {firstDropdown && secondDropdown && (
        <div className="mt-5">
          <h2 className="text-center">Annual Values</h2>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Año</th>
                <th>Valor de Mercado</th>
                <th>Avaluo</th>
                <th>Tax</th>
                <th>Administracion 2</th>
              </tr>
            </thead>
            <tbody>
              {formattedAnnualValues.map((value, index) => (
                <tr key={index}>
                  <td>{value.ano}</td>
                  <td>{value.Valor_de_Mercado ? parseFloat(value.Valor_de_Mercado).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 'N/A'}</td>
                  <td>{value.Avaluo ? parseFloat(value.Avaluo).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 'N/A'}</td>
                  <td>{value.Tax ? parseFloat(value.Tax).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 'N/A'}</td>
                  <td>{value.Administracion_2 ? parseFloat(value.Administracion_2).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  ) : showManageAccessPage ? (
      <div>
        <h1 className="text-center mt-4">Manage Email Access</h1>
        <button className="btn btn-secondary mb-4 custom-btn" onClick={() => setShowManageAccessPage(false)}>Back to Menu</button>
        <form>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">Email:</label>
                <div className="col-sm-8">
                  {isAddingNewEmail ? (
                    <input
                      type="email"
                      className="form-control"
                      value={newEmail}
                      onChange={handleNewEmailChange}
                      placeholder="Enter new email"
                    />
                  ) : (
                    <select
                      className="form-control"
                      value={selectedEmail ? selectedEmail.value : ''}
                      onChange={handleEmailChange}>
                      <option value="">Select an Email</option>
                      {emailOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="col-sm-2">
                  <button 
                    type="button"
                    onClick={toggleEmailInput} 
                    className="btn btn-primary custom-btn mr-2">
                    {isAddingNewEmail ? 'Select' : 'New'}
                  </button>
                </div>
              </div>
              <div className="form-group">
                <label>Propietarios:</label>
                {departments.map((department, index) => (
                  <div key={index} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={department.value}
                      checked={selectedOwners.includes(department.value)}
                      onChange={() => handleOwnerSelection(department.value)}
                      id={`checkbox-${department.value}`}
                    />
                    <label className="form-check-label" htmlFor={`checkbox-${department.value}`}>
                      {department.label}
                    </label>
                  </div>
                ))}
              </div>
              <div className="btn-group" role="group" aria-label="Basic example">
                <button type="button" className="btn btn-success custom-btn" onClick={() => submitAccessChange('add')}>Add Access</button>
                <button type="button" className="btn btn-danger custom-btn" onClick={() => submitAccessChange('remove')}>Remove Access</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    ) : showDeleteMonthPage ? (
      <div>
        <h1 className="text-center mt-4">Delete Data for a Month</h1>
        <button className="btn btn-secondary mb-4 custom-btn" onClick={() => setShowDeleteMonthPage(false)}>Back to Menu</button>
        <form>
          <div className="form-group">
            <label>Year:</label>
            <select className="form-control" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
              <option value="" disabled>Select a Year</option>
              {years.map(year => <option key={year} value={year}>{year}</option>)}
            </select>
          </div>
          <div className="form-group">
            <label>Month:</label>
            <select className="form-control" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
              <option value="" disabled>Select a Month</option>
              {months.map(month => <option key={month} value={month}>{month}</option>)}
            </select>
          </div>
          <button type="button" className="btn btn-danger custom-btn" onClick={handleDeleteMonth}>Delete</button>
        </form>
      </div>
    ) : showUploadDataPage ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div>
          <h1 className="text-center">Upload Data</h1>
          <div className="text-center mb-4">
              <button className="btn btn-secondary custom-btn" onClick={() => setShowUploadDataPage(false)}>Back to Menu</button>
          </div>
          <form onSubmit={handleFileUpload} className="text-center">
              <div className="form-group">
                  <label>Data File:</label>
                  <input type="file" className="form-control-file " onChange={handleFileChange} />
              </div>
              <button type="submit" className="btn btn-success custom-btn">Upload</button>
          </form>
      </div>
  </div>
  ) : isAdmin ? (
    <div className="text-center mt-4">
      <h1>Welcome to Property Management - Admin</h1>
      <div className="d-flex justify-content-center">
        <button className="btn btn-primary custom-btn mr-2" onClick={() => setShowUploadDataPage(true)}>Upload Data</button>
        <button className="btn btn-primary custom-btn mr-2" onClick={() => setShowUpdatePropertyPage(true)}>Update Property Information</button>
        <button className="btn btn-primary custom-btn mr-2" onClick={() => setShowInsertAnnualValuesPage(true)}>Insert Annual Values</button>
        <button className="btn btn-primary custom-btn ml-2" onClick={() => setShowManageAccessPage(true)}>Manage User Properties Access</button>
        <button className="btn btn-danger custom-btn ml-2" onClick={() => setShowDeleteMonthPage(true)}>Delete Month Data</button>
      </div>
    </div>
  ) : (
    <div className="text-center mt-4">
      <h1>Welcome to Property Management</h1>
      <div className="d-flex justify-content-center">
        <button className="btn btn-primary custom-btn mr-2" onClick={() => setShowUpdatePropertyPage(true)}>Update Property Information</button>
        <button className="btn btn-primary custom-btn mr-2" onClick={() => setShowInsertAnnualValuesPage(true)}>Insert Annual Values</button>
      </div>
    </div>
  )}
</div>
);
}

export default App;
